<template>
  <div class="container pd100">
    <div v-show="!loading">
      <van-cell title="课程信息" />
      <div class="card">
        <van-image class="card-media" :src="detail.course_cover" />
        <div class="card-inner">
          <div class="card-title ui-ellipsis">{{ detail.course_title }}</div>
          <div class="card-txt">{{ detail.class_date }}</div>
          <div class="card-txt">上课学员：{{ detail.course_student.student_name }}</div>
        </div>
      </div>
      <div class="teacher mb10">
        <van-cell title="上课老师" />
        <div class="teacher-inner">
          <div class="teacher-hd">
            <van-image class="teacher-avatar" :src="course_teacher.teacher_avatar" />
            <span class="teacher-title">{{ course_teacher.teacher_name }}</span>
          </div>
          <!-- <div class="teacher-bd" @click="onNavigator({path:'/teacher/detail?teacher_id='+ course_teacher.teacher_id})"><span>查看详情</span><van-icon name="arrow" /></div> -->
        </div>
      </div>
      <div class="form">
        <van-field name="rate" allow-half label="评分">
          <template #input>
            <van-rate
              v-model="rate"
              allow-half
              void-icon="star"
              void-color="#eee"
              :color="globalData.theme.color1"
            />
          </template>
        </van-field>
        <div class="box">
          <van-field
            v-model="remark"
            required
            name="remark"
            rows="4"
            autosize
            type="textarea"
            maxlength="1000"
            placeholder="请填写评论"
          />
          <van-field>
            <template #input>
              <van-uploader v-model="uploaImgs" :max-count="3" :after-read="afterRead" />
            </template>
          </van-field>
          <div class="image-tips">最多可上传3张图片</div>
        </div>
      </div>
      <div class="affix-bar">
        <van-button type="primary" block @click="onSubmit">提交评价</van-button>
      </div>
    </div>
    <loadings :loading='loading' />
  </div>
</template>

<script>
import Loadings from '@/components/loading'
import { Field, Uploader, Picker, Rate } from 'vant'
import PageMixin from '@/mixins/page'
import { isEmpty } from '@/common/validate'
import upload from '@/utils/upload'

export default {
  name: 'StudentSubmitComment',
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Picker.name]: Picker,
    [Rate.name]: Rate,
    loadings:Loadings,
  },
  filters: {
    formatEnd(value) {
      return value && value.slice(-8)
    }
  },
  mixins: [PageMixin],
  data() {
    return {
      loading:false,
      detail: {},
      course_studio: {},
      course_teacher: {},

      remark: '',
      uploaImgs: [],
      rate: 5
    }
  },
  created() {
    this.loading = true
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.$api.schedule_detail({ book_id: this.$route.query.book_id }).then(res => {
        this.detail = res.data
        this.course_studio = res.data.course_studio
        this.course_teacher = res.data.course_teacher
        this.loading = false
      })
    },
    onConfirm(value, index) {
      this.reason = value
      this.showPicker = false
    },
    afterRead(file, detail) {
      file.status = 'uploading'
      file.message = '上传中...'
      upload({ file: file.file })
        .then(res => {
          this.uploaImgs.splice(detail.index, 1, { url: res.path, status: 'done', message: '上传成功' })
        })
        .catch(err => {
          console.error(err)
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    onSubmit() {
      if (isEmpty(this.remark)) {
        this.$toast('请填写评论内容')
        return
      }
      this.$toast.loading({
        mask: true,
        message: '提交中...',
        duration: 0
      })
      const params = {
        comment_content: this.remark,
        comment_image: this.uploaImgs.map(item => item.url),
        comment_entity: this.detail.comment_entity,
        comment_rate: this.rate
      }

      this.$api.schedule_addcomment(params)
        .then(res => {
          this.$toast.success('提交成功')
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('提交失败')
          console.error(err)
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .card{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    &-media{
      width: 130px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
    }
    &-inner{
      flex: 1;
      height: 60px;
    }
    &-title{
      max-width: 140px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }
  .teacher{
    &-inner{
      padding: 10px 15px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
    }
    &-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    &-title{
      font-size: 14px;
      color: #333;
    }
    &-hd{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-bd{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      color: #666;
    }
  }

  .image-tips{
    padding: 10px 16px;
    font-size: 14px;
    color: #666;
    background: #fff;
  }
</style>

